// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Link from "../../../shared/components/Link.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as Fetch from "../../../../../node_modules/bs-fetch/src/Fetch.bs.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as Caml_obj from "../../../../../node_modules/rescript/lib/es6/caml_obj.js";
import * as Js_array from "../../../../../node_modules/rescript/lib/es6/js_array.js";
import * as ArrayUtils from "../../../shared/utils/ArrayUtils.bs.js";
import * as Js_promise from "../../../../../node_modules/rescript/lib/es6/js_promise.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as ScrollLock from "../../../shared/utils/ScrollLock.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as OptionUtils from "../../../shared/utils/OptionUtils.bs.js";
import * as Caml_exceptions from "../../../../../node_modules/rescript/lib/es6/caml_exceptions.js";
import * as RescriptReactRouter from "../../../../../node_modules/@rescript/react/src/RescriptReactRouter.bs.js";
import * as CoursesCurriculum__Quiz from "./CoursesCurriculum__Quiz.bs.js";
import * as CoursesCurriculum__User from "../types/CoursesCurriculum__User.bs.js";
import * as CoursesCurriculum__Learn from "./CoursesCurriculum__Learn.bs.js";
import * as CoursesCurriculum__Course from "../types/CoursesCurriculum__Course.bs.js";
import * as CoursesCurriculum__Target from "../types/CoursesCurriculum__Target.bs.js";
import * as CoursesCurriculum__Discuss from "./CoursesCurriculum__Discuss.bs.js";
import * as CoursesCurriculum__AutoVerify from "./CoursesCurriculum__AutoVerify.bs.js";
import * as CoursesCurriculum__Submission from "../types/CoursesCurriculum__Submission.bs.js";
import * as CoursesCurriculum__TargetStatus from "../types/CoursesCurriculum__TargetStatus.bs.js";
import * as CoursesCurriculum__TargetDetails from "../types/CoursesCurriculum__TargetDetails.bs.js";
import * as CoursesCurriculum__LatestSubmission from "../types/CoursesCurriculum__LatestSubmission.bs.js";
import * as CoursesCurriculum__SubmissionBuilder from "./CoursesCurriculum__SubmissionBuilder.bs.js";
import * as CoursesCurriculum__CompletionInstructions from "./CoursesCurriculum__CompletionInstructions.bs.js";
import * as CoursesCurriculum__SubmissionsAndFeedback from "./CoursesCurriculum__SubmissionsAndFeedback.bs.js";

var UnexpectedSubmissionStatus = /* @__PURE__ */Caml_exceptions.create("CoursesCurriculum__Overlay.UnexpectedSubmissionStatus");

import "./CoursesCurriculum__Overlay.css"
;

function str(prim) {
  return prim;
}

var partial_arg = "components.CoursesCurriculum__Overlay";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

var initialState = {
  targetDetails: undefined,
  tab: /* Learn */0
};

function reducer(state, action) {
  if (typeof action === "number") {
    if (action === /* ResetState */0) {
      return initialState;
    } else {
      return {
              targetDetails: undefined,
              tab: /* Learn */0
            };
    }
  }
  switch (action.TAG | 0) {
    case /* Select */0 :
        return {
                targetDetails: state.targetDetails,
                tab: action._0
              };
    case /* SetTargetDetails */1 :
        return {
                targetDetails: action._0,
                tab: state.tab
              };
    case /* AddSubmission */2 :
        if (action._0) {
          return {
                  targetDetails: OptionUtils.map(CoursesCurriculum__TargetDetails.clearPendingUserIds, state.targetDetails),
                  tab: state.tab
                };
        } else {
          return state;
        }
    
  }
}

function closeOverlay(course) {
  RescriptReactRouter.push("/courses/" + (CoursesCurriculum__Course.id(course) + "/curriculum"));
}

function loadTargetDetails(target, send, param) {
  Js_promise.then_((function (json) {
          return Promise.resolve(Curry._1(send, {
                          TAG: /* SetTargetDetails */1,
                          _0: CoursesCurriculum__TargetDetails.decode(json)
                        }));
        }), Js_promise.then_(Fetch.$$Response.json, fetch("/targets/" + (CoursesCurriculum__Target.id(target) + "/details_v2"))));
}

function completionTypeToString(completionType, targetStatus) {
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var exit = 0;
  if (typeof match === "number") {
    if (match !== 0) {
      exit = 2;
    } else {
      switch (completionType) {
        case /* Evaluated */0 :
            return t(undefined, undefined, "completion_tab_complete");
        case /* TakeQuiz */1 :
            return t(undefined, undefined, "completion_tab_take_quiz");
        case /* LinkToComplete */2 :
            return t(undefined, undefined, "completion_tab_visit_link");
        case /* MarkAsComplete */3 :
            return t(undefined, undefined, "completion_tab_mark_complete");
        case /* SubmitForm */4 :
            return t(undefined, undefined, "completion_tab_submit_form");
        
      }
    }
  } else {
    var match$1 = match._0;
    if (typeof match$1 === "number" && match$1 < 2) {
      exit = 2;
    }
    
  }
  if (exit === 2) {
    switch (completionType) {
      case /* Evaluated */0 :
          return t(undefined, undefined, "completion_tab_submissions");
      case /* TakeQuiz */1 :
          return t(undefined, undefined, "completion_tab_quiz_result");
      case /* LinkToComplete */2 :
      case /* MarkAsComplete */3 :
          break;
      case /* SubmitForm */4 :
          return t(undefined, undefined, "completion_tab_form_response");
      
    }
  }
  if (typeof match === "number") {
    return t(undefined, undefined, "completion_tab_completed");
  } else {
    return t(undefined, undefined, "completion_tab_locked");
  }
}

function tabToString(targetStatus, tab) {
  if (typeof tab === "number") {
    if (tab !== 0) {
      return t(undefined, undefined, "discuss_tab");
    } else {
      return t(undefined, undefined, "learn_tab");
    }
  } else {
    return completionTypeToString(tab._0, targetStatus);
  }
}

function selectableTabs(targetDetails) {
  if (Caml_obj.equal(CoursesCurriculum__TargetDetails.communities(targetDetails), [])) {
    return [/* Learn */0];
  } else {
    return [
            /* Learn */0,
            /* Discuss */1
          ];
  }
}

function tabClasses(selection, tab) {
  return "course-overlay__body-tab-item p-2 md:px-3 md:py-4 flex w-full items-center justify-center text-sm -mx-px font-semibold focus:outline-none focus:ring-2 focus:ring-inset focus:ring-focusColor-500" + (
          Caml_obj.equal(tab, selection) ? " course-overlay__body-tab-item--selected" : " bg-gray-50 hover:text-primary-400 hover:bg-gray-50 focus:text-primary-400 focus:bg-gray-50 cursor-pointer"
        );
}

function scrollCompleteButtonIntoViewEventually(param) {
  setTimeout((function (param) {
          var element = document.getElementById("auto-verify-target");
          if (element == null) {
            Rollbar.error("Could not find the 'Complete' button to scroll to.");
          } else {
            element.scrollIntoView();
            element.className = "mt-4 complete-button-selected";
          }
        }), 50);
}

function handleTablink(send, _event) {
  Curry._1(send, {
        TAG: /* Select */0,
        _0: /* Learn */0
      });
  scrollCompleteButtonIntoViewEventually(undefined);
}

function tabButton(tab, state, send, targetStatus) {
  return React.createElement("button", {
              key: "select-" + tabToString(targetStatus, tab),
              "aria-selected": Caml_obj.equal(tab, state.tab),
              className: tabClasses(tab, state.tab),
              role: "tab",
              onClick: (function (_e) {
                  Curry._1(send, {
                        TAG: /* Select */0,
                        _0: tab
                      });
                })
            }, tabToString(targetStatus, tab));
}

function tabLink(tab, state, send, targetStatus) {
  return React.createElement("button", {
              className: tabClasses(tab, state.tab),
              onClick: (function (param) {
                  Curry._1(send, {
                        TAG: /* Select */0,
                        _0: /* Learn */0
                      });
                  return scrollCompleteButtonIntoViewEventually(undefined);
                })
            }, tabToString(targetStatus, tab));
}

function tabOptions(state, send, targetDetails, targetStatus) {
  var completionType = CoursesCurriculum__TargetDetails.computeCompletionType(targetDetails);
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var tmp;
  if (typeof match === "number") {
    tmp = completionType === 3 || completionType === 2 ? tabLink(/* Complete */{
            _0: completionType
          }, state, send, targetStatus) : tabButton(/* Complete */{
            _0: completionType
          }, state, send, targetStatus);
  } else {
    var match$1 = match._0;
    tmp = typeof match$1 === "number" && !(match$1 >= 2 || completionType === 3 || completionType === 2 || !Caml_obj.notequal(CoursesCurriculum__TargetDetails.submissions(targetDetails), [])) ? tabButton(/* Complete */{
            _0: completionType
          }, state, send, targetStatus) : null;
  }
  return React.createElement("div", {
              className: "flex justify-between max-w-3xl mx-auto -mb-px mt-5 md:mt-7",
              role: "tablist"
            }, Js_array.map((function (selection) {
                    return tabButton(selection, state, send, targetStatus);
                  }), selectableTabs(targetDetails)), tmp);
}

function addSubmission(target, state, send, addSubmissionCB, submission) {
  var targetDetails = state.targetDetails;
  if (targetDetails !== undefined) {
    var newTargetDetails = CoursesCurriculum__TargetDetails.addSubmission(submission, targetDetails);
    Curry._1(send, {
          TAG: /* SetTargetDetails */1,
          _0: newTargetDetails
        });
  }
  var match = CoursesCurriculum__Submission.status(submission);
  switch (match) {
    case /* MarkedAsComplete */0 :
        return Curry._1(addSubmissionCB, CoursesCurriculum__LatestSubmission.make(false, CoursesCurriculum__Target.id(target)));
    case /* Pending */1 :
        return Curry._1(addSubmissionCB, CoursesCurriculum__LatestSubmission.make(true, CoursesCurriculum__Target.id(target)));
    case /* Completed */2 :
        throw {
              RE_EXN_ID: UnexpectedSubmissionStatus,
              _1: "CoursesCurriculum__Overlay.addSubmission cannot handle a submsision with status Completed",
              Error: new Error()
            };
    case /* Rejected */3 :
        throw {
              RE_EXN_ID: UnexpectedSubmissionStatus,
              _1: "CoursesCurriculum__Overlay.addSubmission cannot handle a submsision with status Rejected",
              Error: new Error()
            };
    
  }
}

function addVerifiedSubmission(target, state, send, addSubmissionCB, submission) {
  var targetDetails = state.targetDetails;
  if (targetDetails !== undefined) {
    var newTargetDetails = CoursesCurriculum__TargetDetails.addSubmission(submission, targetDetails);
    Curry._1(send, {
          TAG: /* SetTargetDetails */1,
          _0: newTargetDetails
        });
  }
  return Curry._1(addSubmissionCB, CoursesCurriculum__LatestSubmission.make(false, CoursesCurriculum__Target.id(target)));
}

function targetStatusClass(prefix, targetStatus) {
  return prefix + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
}

function renderTargetStatus(targetStatus) {
  var className = "curriculum__target-status bg-white text-xs mt-2 md:mt-0 py-1 px-2 md:px-4 curriculum__target-status--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
  return ReactUtils.nullIf(React.createElement("div", {
                  className: className
                }, CoursesCurriculum__TargetStatus.statusToString(targetStatus)), CoursesCurriculum__TargetStatus.isPending(targetStatus));
}

function overlayHeaderTitleCardClasses(targetStatus) {
  return "course-overlay__header-title-card relative flex justify-between items-center px-3 py-3 md:p-6 course-overlay__header-title-card--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus);
}

function renderLocked(text) {
  return React.createElement("div", {
              className: "mx-auto text-center bg-gray-900 text-white max-w-fc px-4 py-2 text-sm font-semibold relative z-10 rounded-b-lg"
            }, React.createElement("i", {
                  className: "fas fa-lock text-lg"
                }), React.createElement("span", {
                  className: "ms-2"
                }, text));
}

function overlayStatus(course, target, targetStatus, preview) {
  return React.createElement("div", undefined, React.createElement("div", {
                  className: overlayHeaderTitleCardClasses(targetStatus)
                }, React.createElement("button", {
                      "aria-label": t(undefined, undefined, "close_button"),
                      className: "course-overlay__close xl:absolute flex flex-col items-center justify-center absolute rounded-t-lg lg:rounded-t-none lg:rounded-b-lg leading-tight px-4 py-1 h-8 lg:h-full cursor-pointer border border-b-0 lg:border-transparent lg:border-t-0 lg:shadow hover:text-gray-900 hover:shadow-md focus:border-gray-300 focus:outline-none focus:text-gray-900 course-overlay__close--" + CoursesCurriculum__TargetStatus.statusClassesSufix(targetStatus),
                      onClick: (function (_e) {
                          closeOverlay(course);
                        })
                    }, React.createElement(Icon.make, {
                          className: "if i-times-regular text-xl lg:text-2xl mt-1 lg:mt-0"
                        }), React.createElement("span", {
                          className: "text-xs hidden lg:inline-block mt-px"
                        }, t(undefined, undefined, "close_button"))), React.createElement("div", {
                      className: "w-full flex flex-wrap md:flex-nowrap items-center justify-between relative"
                    }, React.createElement("div", {
                          className: "flex flex-col md:flex-row items-start md:items-center font-medium leading-snug"
                        }, CoursesCurriculum__Target.milestone(target) ? React.createElement("div", {
                                className: "flex items-center flex-shrink-0 text-xs font-medium bg-yellow-100 text-yellow-800 border border-yellow-300 px-1.5 md:px-2 py-1 rounded-md mr-2"
                              }, React.createElement(Icon.make, {
                                    className: "if i-milestone-solid text-sm"
                                  }), React.createElement("span", {
                                    className: "ms-1"
                                  }, t(undefined, undefined, "milestone_target_label"))) : null, React.createElement("h1", {
                              className: "text-base leading-snug md:me-6 md:text-xl"
                            }, CoursesCurriculum__Target.title(target))), renderTargetStatus(targetStatus))), ReactUtils.nullUnless(React.createElement("div", undefined, renderLocked(t(undefined, undefined, "preview_mode_text"))), preview));
}

function renderLockReason(reason) {
  return renderLocked(CoursesCurriculum__TargetStatus.lockReasonToString(reason));
}

function prerequisitesIncomplete(reason, target, targets, statusOfTargets, send) {
  var prerequisiteTargetIds = CoursesCurriculum__Target.prerequisiteTargetIds(target);
  var prerequisiteTargets = Js_array.filter((function (target) {
          return Js_array.includes(CoursesCurriculum__Target.id(target), prerequisiteTargetIds);
        }), targets);
  return React.createElement("div", {
              className: "relative px-3 md:px-0"
            }, renderLocked(CoursesCurriculum__TargetStatus.lockReasonToString(reason)), React.createElement("div", {
                  className: "course-overlay__prerequisite-targets z-10 max-w-3xl mx-auto bg-white text-center rounded-lg overflow-hidden shadow mt-6"
                }, Js_array.map((function (target) {
                        var targetStatus = ArrayUtils.unsafeFind((function (ts) {
                                return CoursesCurriculum__TargetStatus.targetId(ts) === CoursesCurriculum__Target.id(target);
                              }), "Could not find status of target with ID " + CoursesCurriculum__Target.id(target), statusOfTargets);
                        return React.createElement(Link.make, {
                                    href: "/targets/" + CoursesCurriculum__Target.id(target),
                                    ariaLabel: "Select Target " + CoursesCurriculum__Target.id(target),
                                    className: "bg-white border-t px-6 py-4 relative z-10 flex items-center justify-between hover:bg-gray-50 hover:text-primary-500 cursor-pointer",
                                    onClick: (function (param) {
                                        Curry._1(send, /* ResetState */0);
                                      }),
                                    children: null,
                                    key: CoursesCurriculum__Target.id(target)
                                  }, React.createElement("span", {
                                        className: "font-semibold  leading-snug"
                                      }, CoursesCurriculum__Target.title(target)), renderTargetStatus(targetStatus));
                      }), prerequisiteTargets)));
}

function handleLocked(target, targets, targetStatus, statusOfTargets, send) {
  var reason = CoursesCurriculum__TargetStatus.status(targetStatus);
  if (typeof reason === "number") {
    return null;
  }
  var reason$1 = reason._0;
  if (typeof reason$1 === "number" && reason$1 >= 2) {
    return prerequisitesIncomplete(reason$1, target, targets, statusOfTargets, send);
  } else {
    return renderLocked(CoursesCurriculum__TargetStatus.lockReasonToString(reason$1));
  }
}

function overlayContentClasses(bool) {
  if (bool) {
    return "";
  } else {
    return "hidden";
  }
}

function learnSection(send, targetDetails, tab, author, courseId, targetId, targetStatus, completionType) {
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var suffixLinkInfo;
  if (typeof match === "number" && !(match === 2 || match === 1)) {
    switch (completionType) {
      case /* Evaluated */0 :
          suffixLinkInfo = [
            /* Complete */{
              _0: completionType
            },
            t(undefined, undefined, "learn_cta_submit_work"),
            "fas fa-feather-alt"
          ];
          break;
      case /* TakeQuiz */1 :
          suffixLinkInfo = [
            /* Complete */{
              _0: completionType
            },
            t(undefined, undefined, "learn_cta_take_quiz"),
            "fas fa-tasks"
          ];
          break;
      case /* LinkToComplete */2 :
      case /* MarkAsComplete */3 :
          suffixLinkInfo = undefined;
          break;
      case /* SubmitForm */4 :
          suffixLinkInfo = [
            /* Complete */{
              _0: completionType
            },
            t(undefined, undefined, "learn_cta_submit_form"),
            "fas fa-feather-alt"
          ];
          break;
      
    }
  } else {
    suffixLinkInfo = undefined;
  }
  var linkToTab = Belt_Option.mapWithDefault(suffixLinkInfo, null, (function (param) {
          var tab = param[0];
          return React.createElement("button", {
                      className: "cursor-pointer mt-5 flex rounded btn-success text-lg justify-center w-full font-bold p-4 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 curriculum-overlay__learn-submit-btn",
                      onClick: (function (param) {
                          Curry._1(send, {
                                TAG: /* Select */0,
                                _0: tab
                              });
                        })
                    }, React.createElement("span", undefined, React.createElement(FaIcon.make, {
                              classes: param[2] + " me-2"
                            }), param[1]));
        }));
  return React.createElement("div", {
              className: tab === /* Learn */0 ? "" : "hidden"
            }, React.createElement(CoursesCurriculum__Learn.make, {
                  targetDetails: targetDetails,
                  author: author,
                  courseId: courseId,
                  targetId: targetId
                }), linkToTab);
}

function discussSection(target, targetDetails, tab) {
  return React.createElement("div", {
              className: tab === /* Discuss */1 ? "" : "hidden"
            }, React.createElement(CoursesCurriculum__Discuss.make, {
                  targetId: CoursesCurriculum__Target.id(target),
                  communities: CoursesCurriculum__TargetDetails.communities(targetDetails)
                }));
}

function completeSectionClasses(tab, completionType) {
  if (typeof tab === "number" && (tab !== 0 || !(completionType === 3 || completionType === 2))) {
    return "hidden";
  } else {
    return "";
  }
}

function completeSection(state, send, target, targetDetails, targetStatus, addSubmissionCB, evaluationCriteria, coaches, users, preview, completionType) {
  var addVerifiedSubmissionCB = function (param) {
    return addVerifiedSubmission(target, state, send, addSubmissionCB, param);
  };
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  var tmp;
  var exit = 0;
  var exit$1 = 0;
  if (typeof match === "number") {
    if (match !== 0) {
      exit$1 = 2;
    } else {
      switch (completionType) {
        case /* Evaluated */0 :
            tmp = [
              React.createElement(CoursesCurriculum__CompletionInstructions.make, {
                    targetDetails: targetDetails,
                    title: "Anleitung",
                    key: "completion-instructions"
                  }),
              React.createElement(CoursesCurriculum__SubmissionBuilder.make, {
                    target: target,
                    targetDetails: targetDetails,
                    addSubmissionCB: (function (param) {
                        return addSubmission(target, state, send, addSubmissionCB, param);
                      }),
                    preview: preview,
                    checklist: CoursesCurriculum__TargetDetails.checklist(targetDetails),
                    key: "courses-curriculum-submission-form"
                  })
            ];
            break;
        case /* TakeQuiz */1 :
            tmp = [
              React.createElement(CoursesCurriculum__CompletionInstructions.make, {
                    targetDetails: targetDetails,
                    title: "Anleitung",
                    key: "completion-instructions"
                  }),
              React.createElement(CoursesCurriculum__Quiz.make, {
                    target: target,
                    targetDetails: targetDetails,
                    addSubmissionCB: addVerifiedSubmissionCB,
                    preview: preview,
                    key: "courses-curriculum-quiz"
                  })
            ];
            break;
        case /* LinkToComplete */2 :
        case /* MarkAsComplete */3 :
            exit = 1;
            break;
        case /* SubmitForm */4 :
            tmp = [
              React.createElement(CoursesCurriculum__CompletionInstructions.make, {
                    targetDetails: targetDetails,
                    title: "Anleitung",
                    key: "completion-instructions"
                  }),
              React.createElement(CoursesCurriculum__SubmissionBuilder.make, {
                    target: target,
                    targetDetails: targetDetails,
                    addSubmissionCB: (function (param) {
                        return addSubmission(target, state, send, addSubmissionCB, param);
                      }),
                    preview: preview,
                    checklist: CoursesCurriculum__TargetDetails.checklist(targetDetails),
                    key: "courses-curriculum-submission-form"
                  })
            ];
            break;
        
      }
    }
  } else {
    var match$1 = match._0;
    if (typeof match$1 === "number" && match$1 < 2) {
      exit$1 = 2;
    } else {
      exit = 1;
    }
  }
  if (exit$1 === 2) {
    if (completionType === 3 || completionType === 2) {
      exit = 1;
    } else {
      tmp = React.createElement(CoursesCurriculum__SubmissionsAndFeedback.make, {
            targetDetails: targetDetails,
            target: target,
            evaluationCriteria: evaluationCriteria,
            addSubmissionCB: (function (param) {
                return addSubmission(target, state, send, addSubmissionCB, param);
              }),
            targetStatus: targetStatus,
            coaches: coaches,
            users: users,
            preview: preview,
            checklist: CoursesCurriculum__TargetDetails.checklist(targetDetails)
          });
    }
  }
  if (exit === 1) {
    tmp = typeof match === "number" ? React.createElement(CoursesCurriculum__AutoVerify.make, {
            target: target,
            targetDetails: targetDetails,
            targetStatus: targetStatus,
            addSubmissionCB: addVerifiedSubmissionCB,
            preview: preview
          }) : null;
  }
  return React.createElement("div", {
              className: completeSectionClasses(state.tab, completionType)
            }, tmp);
}

function renderPendingStudents(pendingUserIds, users) {
  return React.createElement("div", {
              className: "max-w-3xl mx-auto text-center mt-4"
            }, React.createElement("div", {
                  className: "font-semibold text-md"
                }, t(undefined, undefined, "pending_team_members_notice")), React.createElement("div", {
                  className: "flex justify-center flex-wrap"
                }, Js_array.map((function (studentId) {
                        var user = ArrayUtils.unsafeFind((function (u) {
                                return CoursesCurriculum__User.id(u) === studentId;
                              }), "Unable to find user with id " + (studentId + "in CoursesCurriculum__Overlay"), users);
                        return React.createElement("div", {
                                    key: CoursesCurriculum__User.id(user),
                                    className: "w-10 h-10 rounded-full border border-yellow-400 flex items-center justify-center overflow-hidden mx-1 shadow-md shrink-0 mt-2",
                                    title: CoursesCurriculum__User.name(user) + " has not completed this target."
                                  }, CoursesCurriculum__User.avatar(user));
                      }), pendingUserIds)));
}

function handlePendingStudents(targetStatus, targetDetails, users) {
  var match = CoursesCurriculum__TargetStatus.status(targetStatus);
  if (targetDetails === undefined) {
    return null;
  }
  if (typeof match !== "number") {
    return null;
  }
  if (!(match === 2 || match === 1)) {
    return null;
  }
  var pendingUserIds = CoursesCurriculum__TargetDetails.pendingUserIds(targetDetails);
  if (Caml_obj.equal(pendingUserIds, [])) {
    return null;
  } else {
    return renderPendingStudents(pendingUserIds, users);
  }
}

function performQuickNavigation(send, _event) {
  var element = document.getElementById("target-overlay");
  if (!(element == null)) {
    element.scrollTop = 0.0;
  }
  return Curry._1(send, /* PerformQuickNavigation */1);
}

function navigationLink(direction, url, send) {
  var match = direction === "Next" ? [
      undefined,
      t(undefined, undefined, "next_target_button"),
      "fa-arrow-right"
    ] : [
      "fa-arrow-left",
      t(undefined, undefined, "previous_target_button"),
      undefined
    ];
  var arrow = function (icon) {
    return Belt_Option.mapWithDefault(icon, null, (function (icon) {
                  return React.createElement(FaIcon.make, {
                              classes: "rtl:rotate-180 fas " + icon
                            });
                }));
  };
  return React.createElement(Link.make, {
              href: url,
              className: "block p-2 md:p-4 text-center border rounded-lg bg-gray-50 hover:bg-gray-50",
              onClick: (function (param) {
                  return performQuickNavigation(send, param);
                }),
              children: null
            }, arrow(match[0]), React.createElement("span", {
                  className: "mx-2 hidden md:inline"
                }, match[1]), arrow(match[2]));
}

function scrollOverlayToTop(_event) {
  var element = document.getElementById("target-overlay");
  Belt_Option.mapWithDefault((element == null) ? undefined : Caml_option.some(element), undefined, (function (element) {
          element.scrollTop = 0.0;
        }));
}

function quickNavigationLinks(targetDetails, send) {
  var match = CoursesCurriculum__TargetDetails.navigation(targetDetails);
  return React.createElement("div", {
              className: "pb-6"
            }, React.createElement("hr", {
                  className: "my-6"
                }), React.createElement("div", {
                  className: "container mx-auto max-w-3xl flex px-3 lg:px-0",
                  id: "target-navigation"
                }, React.createElement("div", {
                      className: "w-1/3 me-2"
                    }, Belt_Option.mapWithDefault(match[0], null, (function (previousUrl) {
                            return navigationLink("Previous", previousUrl, send);
                          }))), React.createElement("div", {
                      className: "w-1/3 mx-2"
                    }, React.createElement("button", {
                          className: "block w-full focus:outline-none p-2 md:p-4 text-center border rounded-lg bg-gray-50 hover:bg-gray-50",
                          onClick: scrollOverlayToTop
                        }, React.createElement("span", {
                              className: "mx-2 hidden md:inline"
                            }, t(undefined, undefined, "scroll_to_top")), React.createElement("span", {
                              className: "mx-2 md:hidden"
                            }, React.createElement("i", {
                                  className: "fas fa-arrow-up"
                                })))), React.createElement("div", {
                      className: "w-1/3 ms-2"
                    }, Belt_Option.mapWithDefault(match[1], null, (function (nextUrl) {
                            return navigationLink("Next", nextUrl, send);
                          })))));
}

function updatePendingUserIdsWhenAddingSubmission(send, target, addSubmissionCB, submission) {
  Curry._1(send, {
        TAG: /* AddSubmission */2,
        _0: CoursesCurriculum__Target.role(target)
      });
  return Curry._1(addSubmissionCB, submission);
}

function CoursesCurriculum__Overlay(Props) {
  var target = Props.target;
  var course = Props.course;
  var targetStatus = Props.targetStatus;
  var addSubmissionCB = Props.addSubmissionCB;
  var targets = Props.targets;
  var statusOfTargets = Props.statusOfTargets;
  var users = Props.users;
  var evaluationCriteria = Props.evaluationCriteria;
  var coaches = Props.coaches;
  var preview = Props.preview;
  var author = Props.author;
  var match = React.useReducer(reducer, initialState);
  var send = match[1];
  var state = match[0];
  React.useEffect((function () {
          return loadTargetDetails(target, send, undefined);
        }), [CoursesCurriculum__Target.id(target)]);
  React.useEffect(function () {
        ScrollLock.activate(undefined);
        return (function (param) {
                  ScrollLock.deactivate(undefined);
                });
      });
  var targetDetails = state.targetDetails;
  var targetDetails$1 = state.targetDetails;
  var tmp;
  if (targetDetails$1 !== undefined) {
    var completionType = CoursesCurriculum__TargetDetails.computeCompletionType(targetDetails$1);
    tmp = React.createElement("div", undefined, React.createElement("div", {
              className: "container mx-auto mt-6 md:mt-8 max-w-3xl px-3 lg:px-0"
            }, learnSection(send, targetDetails$1, state.tab, author, CoursesCurriculum__Course.id(course), CoursesCurriculum__Target.id(target), targetStatus, completionType), discussSection(target, targetDetails$1, state.tab), completeSection(state, send, target, targetDetails$1, targetStatus, (function (param) {
                    return updatePendingUserIdsWhenAddingSubmission(send, target, addSubmissionCB, param);
                  }), evaluationCriteria, coaches, users, preview, completionType)), quickNavigationLinks(targetDetails$1, send));
  } else {
    tmp = React.createElement("div", {
          className: "course-overlay__skeleton-body-container max-w-3xl w-full pb-4 mx-auto"
        }, React.createElement("div", {
              className: "course-overlay__skeleton-body-wrapper mt-8 px-3 lg:px-0"
            }, React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-md mt-4 w-1/2 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-3/4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-image-placeholder mt-5 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-2/5 skeleton-animate"
                })), React.createElement("div", {
              className: "course-overlay__skeleton-body-wrapper mt-8 px-3 lg:px-0"
            }, React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-3/4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 skeleton-animate"
                }), React.createElement("div", {
                  className: "course-overlay__skeleton-line-placeholder-sm mt-4 w-3/4 skeleton-animate"
                })));
  }
  return React.createElement("div", {
              className: "fixed z-50 top-0 start-0 w-full h-full overflow-y-scroll bg-white",
              id: "target-overlay"
            }, React.createElement("div", {
                  className: "bg-gray-50 border-b border-gray-300 px-3"
                }, React.createElement("div", {
                      className: "course-overlay__header-container pt-12 lg:pt-0 mx-auto"
                    }, overlayStatus(course, target, targetStatus, preview), handleLocked(target, targets, targetStatus, statusOfTargets, send), handlePendingStudents(targetStatus, state.targetDetails, users), targetDetails !== undefined ? tabOptions(state, send, targetDetails, targetStatus) : React.createElement("div", {
                            className: "course-overlay__skeleton-head-container max-w-3xl w-full mx-auto"
                          }, React.createElement("div", {
                                className: "course-overlay__skeleton-head-wrapper bg-white h-13 flex items-center justify-between border border-b-0 rounded-t-lg mt-5 md:mt-7"
                              }, React.createElement("div", {
                                    className: "course-overlay__skeleton-line-placeholder-sm w-1/3 mx-8 skeleton-animate"
                                  }), React.createElement("div", {
                                    className: "course-overlay__skeleton-line-placeholder-sm w-1/3 mx-8 skeleton-animate"
                                  }), React.createElement("div", {
                                    className: "course-overlay__skeleton-line-placeholder-sm w-1/3 mx-8 skeleton-animate"
                                  }))))), tmp);
}

var TargetStatus;

var make = CoursesCurriculum__Overlay;

export {
  UnexpectedSubmissionStatus ,
  TargetStatus ,
  str ,
  t ,
  initialState ,
  reducer ,
  closeOverlay ,
  loadTargetDetails ,
  completionTypeToString ,
  tabToString ,
  selectableTabs ,
  tabClasses ,
  scrollCompleteButtonIntoViewEventually ,
  handleTablink ,
  tabButton ,
  tabLink ,
  tabOptions ,
  addSubmission ,
  addVerifiedSubmission ,
  targetStatusClass ,
  renderTargetStatus ,
  overlayHeaderTitleCardClasses ,
  renderLocked ,
  overlayStatus ,
  renderLockReason ,
  prerequisitesIncomplete ,
  handleLocked ,
  overlayContentClasses ,
  learnSection ,
  discussSection ,
  completeSectionClasses ,
  completeSection ,
  renderPendingStudents ,
  handlePendingStudents ,
  performQuickNavigation ,
  navigationLink ,
  scrollOverlayToTop ,
  quickNavigationLinks ,
  updatePendingUserIdsWhenAddingSubmission ,
  make ,
}
/*  Not a pure module */
