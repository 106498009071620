// Generated by ReScript, PLEASE EDIT WITH CARE

import * as I18n from "../../../shared/utils/I18n.bs.js";
import * as Icon from "../../../shared/Icon.bs.js";
import * as Curry from "../../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as FaIcon from "../../../shared/components/FaIcon.bs.js";
import * as DateFns from "../../../shared/utils/DateFns.bs.js";
import * as ReactUtils from "../../../shared/utils/ReactUtils.bs.js";
import * as Belt_Option from "../../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as MarkdownBlock from "../../../shared/components/MarkdownBlock.bs.js";
import * as CoursesReview__SubmissionReport from "../types/CoursesReview__SubmissionReport.bs.js";

var partial_arg = "components.CoursesReview__SubmissionReportShow";

function t(param, param$1, param$2) {
  return I18n.t(partial_arg, param, param$1, param$2);
}

function str(prim) {
  return prim;
}

function reducer(state, action) {
  return {
          showReport: !state.showReport
        };
}

function reportStatusIconClasses(report) {
  var match = CoursesReview__SubmissionReport.status(report);
  switch (match) {
    case /* Queued */0 :
        return "if i-clock-light text-2xl text-gray-600 rounded-full";
    case /* InProgress */1 :
        return "if animate-spin i-dashed-circle-regular text-2xl text-yellow-500 rounded-full";
    case /* Success */2 :
        return "if i-check-circle-solid text-2xl text-green-500 bg-white rounded-full";
    case /* Failure */3 :
        return "if i-times-circle-solid text-2xl text-red-500 bg-white rounded-full";
    case /* Error */4 :
        return "if i-exclamation-triangle-circle-solid text-2xl text-gray-600 bg-white rounded-full";
    
  }
}

function reportStatusString(report) {
  var heading = CoursesReview__SubmissionReport.heading(report);
  if (heading !== undefined) {
    return heading;
  }
  var match = CoursesReview__SubmissionReport.status(report);
  switch (match) {
    case /* Queued */0 :
        return t(undefined, undefined, "report_status_string.queued");
    case /* InProgress */1 :
        return t(undefined, undefined, "report_status_string.in_progress");
    case /* Success */2 :
        return t(undefined, undefined, "report_status_string.success");
    case /* Failure */3 :
        return t(undefined, undefined, "report_status_string.failure");
    case /* Error */4 :
        return t(undefined, undefined, "report_status_string.error");
    
  }
}

function reportConclusionTimeString(report) {
  var match = CoursesReview__SubmissionReport.status(report);
  if (match === 1) {
    return "Gestartet " + Belt_Option.mapWithDefault(CoursesReview__SubmissionReport.startedAt(report), "", (function (t) {
                  return DateFns.formatDistanceToNowStrict(t, true, undefined, undefined, undefined);
                }));
  }
  if (match === 0) {
    return "In Warteschlange eingereiht " + DateFns.formatDistanceToNowStrict(CoursesReview__SubmissionReport.queuedAt(report), true, undefined, undefined, undefined);
  }
  var match$1 = CoursesReview__SubmissionReport.startedAt(report);
  var match$2 = CoursesReview__SubmissionReport.completedAt(report);
  return "Abgeschlossen " + Belt_Option.mapWithDefault(CoursesReview__SubmissionReport.completedAt(report), "", (function (t) {
                return DateFns.formatDistanceToNowStrict(t, true, undefined, undefined, undefined);
              })) + (
          match$1 !== undefined && match$2 !== undefined ? " und dauerte " + DateFns.formatDistanceStrict(Caml_option.valFromOption(match$2), Caml_option.valFromOption(match$1), undefined, undefined, undefined, undefined) : ""
        );
}

function CoursesReview__SubmissionReportShow(Props) {
  var report = Props.report;
  var match = React.useReducer(reducer, {
        showReport: false
      });
  var send = match[1];
  var state = match[0];
  var url = CoursesReview__SubmissionReport.targetUrl(report);
  var toggleTestReportIcon = state.showReport ? "i-arrows-collapse-light" : "i-arrows-expand-light";
  var match$1 = state.showReport;
  var match$2 = CoursesReview__SubmissionReport.report(report);
  return React.createElement("div", {
              className: "px-4 py-1 md:py-2"
            }, React.createElement("div", {
                  className: "bg-gray-100 p-2 md:p-4 rounded-md"
                }, React.createElement("div", {
                      className: "flex items-center justify-between text-sm"
                    }, React.createElement("div", {
                          className: "flex items-start gap-3"
                        }, React.createElement("div", {
                              className: "pt-1"
                            }, React.createElement(Icon.make, {
                                  className: reportStatusIconClasses(report)
                                })), React.createElement("div", undefined, React.createElement("div", {
                                  className: "text-xs"
                                }, url !== undefined ? React.createElement("a", {
                                        className: "text-primary-500 underline font-medium hover:text-primary-600",
                                        href: url,
                                        target: "_blank"
                                      }, CoursesReview__SubmissionReport.reporter(report), React.createElement(FaIcon.make, {
                                            classes: "if i-external-link-regular ms-1"
                                          })) : CoursesReview__SubmissionReport.reporter(report)), React.createElement("p", {
                                  className: "font-semibold"
                                }, reportStatusString(report)), React.createElement("p", {
                                  className: "text-gray-600 text-xs mt-1"
                                }, reportConclusionTimeString(report)))), ReactUtils.nullIf(React.createElement("button", {
                              className: "inline-flex items-center text-xs text-gray-800 px-2 py-2 rounded font-semibold hover:bg-gray-300 focus:bg-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-focusColor-500 transition",
                              onClick: (function (param) {
                                  Curry._1(send, /* ChangeReportVisibility */0);
                                })
                            }, React.createElement("span", {
                                  className: "hidden md:block pe-3 "
                                }, state.showReport ? t(undefined, undefined, "hide_report_button") : t(undefined, undefined, "show_report_button")), React.createElement("span", {
                                  className: "inline-block w-5 h-5"
                                }, React.createElement(Icon.make, {
                                      className: "if text-xl " + toggleTestReportIcon
                                    }))), Belt_Option.isNone(CoursesReview__SubmissionReport.report(report)))), match$1 && match$2 !== undefined && state.showReport ? React.createElement("div", undefined, React.createElement("p", {
                            className: "text-sm font-semibold mt-4"
                          }, t(undefined, undefined, "test_report")), React.createElement("div", {
                            className: "bg-white p-3 rounded-md border mt-1"
                          }, React.createElement(MarkdownBlock.make, {
                                markdown: match$2,
                                profile: /* Permissive */0
                              }))) : null));
}

var make = CoursesReview__SubmissionReportShow;

export {
  t ,
  str ,
  reducer ,
  reportStatusIconClasses ,
  reportStatusString ,
  reportConclusionTimeString ,
  make ,
}
/* I18n Not a pure module */
